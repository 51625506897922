<template>
  <div class="StationSearch">
    <Headernav></Headernav>
    <Nav></Nav>

    <!-- 主体部分 -->
    <div class="stationbanner">
      <div style="marigin-top: 40px" class="stationdiv">
        <el-input
          :placeholder="place"
          v-model="input3"
          class="input-with-select"
          style="
            width: 610px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            border-top-stationright-radius: 15px;
            border-bottom-stationright-radius: 15px;
            border: 1px solid #d9d8d8;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 16px 15px 15px 21px !important;
          "
          @keyup.enter.native="entersearch()"
          @input="getInput($event)"
        >
          <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
            "
            v-model="select"
            slot="prepend"
            placeholder="站内"
            @change="currentSel"
          >
            <el-option label="站内" value="1"></el-option>
            <!-- <el-option label="期刊" value="2"></el-option>
            <el-option label="绘本" value="3"></el-option> -->
          </el-select>
          <!-- <el-select
            style="
              width: 120px;
              background-color: #fff;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              margin-left: 10px;
            "
            v-model="select1"
            slot="prepend"
            placeholder="馆藏地"
            @change="chooseplace"
          >
            <el-option label="馆藏地" value="1"></el-option>
            <el-option label="馆藏地1" value="2"></el-option>
            <el-option label="馆藏地2" value="3"></el-option>
          </el-select> -->
          <el-button
            style="
              background-color: #fff;
              border-top-stationright-radius: 15px;
              border-bottom-stationright-radius: 15px;
              background-color: #ae703e;
              color: #fff;
              height: 42px;
            "
            slot="append"
            @click="searchdetail"
            >站内搜索</el-button
          >
        </el-input>
      </div>
      <div class="line"></div>
      <div class="stationright">
        <div
          class="listitem"
          v-on:mouseover="changeActive($event, index)"
          v-on:mouseout="removeActive($event)"
          v-for="(item, index) in resultList"
          :key="item.id"
          @click="changePage(index, item)"
        >
          <div class="zuo">
            <div class="lef"><img :src="item.imgUrl" alt="" /></div>
            <div style="padding: 20px">
              <h3>{{ item.title }}</h3>
              <p v-if="item.publisher">{{ item.publisher }}</p>
              <p v-if="item.content">{{ item.content }}</p>
            </div>
          </div>
          <div class="you">
            <p class="p1" v-if="item.createTime">
              {{ item.createTime | formatDate }}
            </p>
            <p class="p1" v-if="item.pubdate">{{ item.pubdate }}</p>
            <div>
              <img
                v-if="show != index"
                src="../images/zzsr44.png"
                alt=""
                id="s1"
              />
              <div class="searchyyqx" v-if="show == index">详情</div>
            </div>
          </div>
        </div>
        <div class="fenyeqi" v-show="total / 5 > 1">
          <el-pagination
            style="text-align: end"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="5"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Api from "../api/api_StationSearch";
import Nav from "../components/nav.vue";
import API from "../api/api_person";
export default {
  name: "StationSearch",
  data() {
    return {
      input3: "",
      currentPage1: 1,
      select: "",
      show: 999,
      // select1: "",
      resultList: [],
      pageNum: 1,
      pageSize: 5,
      total: 0,
      title: "",
      fromPath: "",
      place: "请输入所需查询的信息",
    };
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  components: {
    Headernav,
    Footer,
    Nav,
  },
  watch: {
    $route(to, from) {
      // 路由发生变化页面刷新
      // this.$router.go(0);
    },
  },
  methods: {
    getInput($event) {
      this.currentPage1 = 1;
    },
    currentSel(label) {
      //获取到筛选框的值
    },
    chooseplace(label) {
      //同上
    },
    //这是搜索列表的样式变换
    changeActive($event, index) {
      $event.currentTarget.className = "searchactive";
      this.show = index;
    },
    removeActive($event) {
      $event.currentTarget.className = "listitem";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      let params = {
        title: this.input3,
        pageNum: this.pageNum,
        pageSize: 5,
      };
      Api.booksearch(params).then((res) => {
        this.resultList = res.data.rows;
        this.total = res.data.total;
      });
    },
    entersearch() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        //搜索
        let params = {
          title: this.input3,
          pageNum: 1,
          pageSize: 5,
        };
        Api.searchResult(params).then((res) => {
          this.resultList = res.data.rows;
          this.total = res.data.total;
        });
      }
    },
    searchdetail() {
      if (this.input3 == "") {
        this.$message({
          message: "请先输入要搜索的内容",
          type: "warning",
        });
      } else {
        //搜索
        let params = {
          title: this.input3,
          pageNum: 1,
          pageSize: 5,
        };
        Api.searchResult(params).then((res) => {
          this.resultList = res.data.rows;
          this.total = res.data.total;
        });
      }
    },
    backlogin() {
      API.exit({}).then((res) => {
        if (res.code == 0) {
          this.$message("退出成功");
          setTimeout(() => {
            this.$router.push({ path: "/accountlogin" });
            localStorage.removeItem("tokenlogin");
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    enterSearchMember() {
   
    },
   
    changePage(index, item) {
      this.$router.push({
        path: "/detail",
        query: {
          id: item.menuId,
          title1: item.title,
          contentType: item.contentType,
          articleId: item.contentId,
        },
      });
    },
  },
  created() {
    const { type, title } = this.$route.query;
    this.input3 = title;
    this.title = title;
    let params = {
      title: this.title,
      pageNum: 1,
      pageSize: 5,
    };
    Api.searchResult(params).then((res) => {
      this.resultList = res.data.rows;
      this.total = res.data.total;
    });
    var lett = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        lett.enterSearchMember();
      }
    };
    this.tokenlogin = localStorage.getItem("tokenlogin");
  },
};
</script>
<style scope>
.stationdiv .el-input__inner:focus {
  border-color: #eeeeee;
}
.StationSearch {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
/* .StationSearch .footer {
   position: fixed; 
} */
.stationright .fenyeqi {
  margin-bottom: 80px;
}
.searresult {
  width: 1118px;
  height: 138px;
  margin: 0 auto;
}
.searchyyqx {
  width: 45px !important;
  height: 17px !important;
  background-color: #ae703e;
  font-size: 7px !important;
  margin-left: 7%;
  padding: 10px 20px;
  color: #fff;
}
.lef img {
  width: 160px;
  height: 90px;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #eeeeee;
  margin-top: 120px;
}
.stationright {
  width: 1118px;
  /* height: 138px; */
  margin: 36px auto;
}
.stationright .listitem {
  width: calc(100% - 40px);
  padding: 20px;
  height: 130px;
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  display: flex;
  margin-bottom: 17px;
}
.stationright .listitem .zuo {
  width: 80%;
  display: flex;
  align-items: center;
}
.stationright .listitem .zuo h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "MicrosoftYaHei";
}
.stationright .listitem .zuo p {
  color: #999999;
  font-size: 14px;
  line-height: 25px;
}
.stationright .listitem .you {
  /* width: 20%;
  text-align: center;
  color: #aaaaaa; */
  display: flex;
  width: 20%;
  align-items: center;
  text-align: center;
  color: #aaaaaa;
  flex-direction: column;
}
.stationright .listitem .you .p1 {
  font-size: 12px !important;
  font-size: 28px;
  margin: 25px 0px;
}
.stationright .searchactive {
  box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -o-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.1);
}
.stationright .searchactive {
  width: calc(100% - 40px);
  padding: 20px;
  height: 130px;
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  display: flex;
  margin-bottom: 10px;
  margin-top: -5px;
}
.stationright .searchactive .zuo {
  width: 80%;
  display: flex;
  align-items: center;
}
.stationright .searchactive .zuo h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "MicrosoftYaHei";
}
.stationright .searchactive .zuo p {
  color: #999999;
  font-size: 14px;
  line-height: 25px;
}

.stationright .searchactive .you {
  /* width: 20%;
  text-align: center;
  color: #aaaaaa; */
  display: flex;
  width: 20%;
  align-items: center;
  text-align: center;
  color: #aaaaaa;
  flex-direction: column;
}
.stationright .searchactive .you .p1 {
  font-size: 12px !important;
  font-size: 28px;
  margin: 25px 0px;
}
.StationSearch .stationbanner {
  width: 100%;
  min-height: 900px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
}
.StationSearch .stationbanner .stationdiv {
  width: 610px;
  top: 3vh;
  left: -5vh;
  margin: 0px auto;
  position: absolute;
  margin-left: calc((100% - 1000px) / 2);
}
.stationdiv .el-input-group__prepend {
  border-radius: 22px !important;
}
.stationdiv .el-input__inner {
  border: 0px solid #dcdfe6;
  border-left: 1px solid #eeeeee;
}
.stationdiv .el-input-group__append {
  border-radius: 0px 15px 15px 0px !important;
}
.stationdiv .el-input-group__append button {
  /* border-left: 0; */
  border-radius: 0px 15px 15px 0px !important;
}
::v-deep
  .stationdiv
  .input-with-select
  .el-input
  .el-input-group
  .el-input-group--append
  .el-input-group--prepend {
  border-radius: 0px 15px 15px 0px !important;
}
.StationSearch .stationbanner .checkradio {
  width: auto;
  margin: 0px auto;
  position: absolute;
  margin-left: calc((100% - 1000px) / 2);
  top: 31vh;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  padding: 0px 15px;
  border-radius: 10px;
}
::v-deep .stationdiv .el-icon-arrow-up:before {
  color: black !important;
}

/* .footer {
  position: fixed;
} */
</style>
