import * as API from '.'
export default {
    // 搜索
    searchResult: params => {
        return API.POST('api/index/search', params)
    },
    // 图书搜索
    booksearch: params => {
        return API.POST('api/index/search', params)
    },
}